(function () {

    "use strict";

    const Sys = {};

    Sys.BaseTask = function () {
        const self = this;

        const _debug = true;

        self._type = "BaseTask";

        // let isComplete = false;
        // let isExecuting = false;

        self.name = null;

        let fn = null;

        self.notifyComplete = function () {
            // if (isComplete) {
            //     return console.warn("A task is firing complete after it is complete!");
            // }
            //
            // isComplete = true;

            if (fn !== null) {
                fn();
            }

            // console.log("This task is complete");
        };

        self.endCall = function ($fn) {
            fn = $fn;
        };

        self.execute = function () {
            // if (isExecuting || isDisposed) {
            //     return;
            // }

            // isExecuting = true;
            // isComplete = false;
        };


    };


    Sys.LoopingQueue = function () {
        const self = this;

        const queue = [];
        let playIndex = 0;

        self.add = function (task) {
            if (typeof task.execute !== "function" && task._type !== "BaseTask") {
                return console.warn("You can only had BaseTask's to LoopingQueues.")
            }

            task.endCall(finished);

            queue.push(task);
        };

        self.remove = function () {

        };

        self.execute = function () {
            if (queue.length <= 0) {
                return console.warn("Can't execute an empty queue");
            }

            queue[playIndex].execute();
        };

        const finished = function () {
            playIndex++;

            if (playIndex === queue.length) {
                playIndex = 0;
            }

            self.execute();
        };

        self.print = function () {
            console.log("Queue: ", queue);
        };

    };


    window.Sys = Sys;
})();