const HEIGHT = 250; // window.innerHeight
const MIN_WIDTH = 450;
const WRAPPER_DIV = "webgl-head";
let READY_TO_RENDER = false;
const GUTTER_SCROLLER = window.innerWidth > MIN_WIDTH ? 19 : 0;
const Noise = noise.noise;

let container, stats, controls;
let camera, scene, renderer;
// PARTICLES VARS
let particles = new THREE.BufferGeometry();
let pMaterial, pointCloud, particlePositions;
let particleAmount = 100;
let particlesData = [];
// PERLIN NOISE
let flying = 0;
let terainVerts, terrain, terrainWire, terainWireVerts, wireframe;
let windowWidth = window.innerWidth - GUTTER_SCROLLER;
const IDD = {"i1": [], "d1": [], "o1": [], "d2": [], "i2": [], "g1": [], "i3": [], "t1": [], "a1": [], "l1": []};
let ANIMATE_TERRAIN = false;
// let fpsCanvasElm;
// ROTATION ON MOUSE
let windowCenterX, isMouseRightOfScreen, rotationVal, normalizedRotationPos, lastChangedMousePos, mouseDirection;
const textureLoader = new THREE.TextureLoader();
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
})(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;
// const isEdge = !isIE && !!window.StyleMedia;

if (!isIE) {
    init();
    animate();
    onWindowResize();
}


function init() {
    container = document.createElement('div');
    container.classList.add("webglDiv");
    document.getElementById(WRAPPER_DIV).appendChild(container);
    // CAMERA
    camera = new THREE.PerspectiveCamera(30, window.innerWidth / HEIGHT, 1, 800);
    camera.position.set(0, 0, 10);
    // ORBIT CONTROLS
    controls = new THREE.OrbitControls(camera);
    controls.enabled = false;
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.update();
    // SCENE
    scene = new THREE.Scene();
    // BACKGROUND
    textureLoader.load('img/background5.png',
        (texture) => {
            // console.log(texture);
            // scene.background = new THREE.Color().setHSL(1, 1, 1);
            if (window.isEdge || isSafari) {
                scene.background = new THREE.Color(0x28282f);
            } else {
                scene.background = texture;
            }

            scene.fog = new THREE.Fog(0x000000, 10, 25);
            READY_TO_RENDER = true;
        }
    );

    // LIGHTS
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.35);
    scene.add(ambientLight);
    const pointLight = new THREE.PointLight(0xff00ff, 1.45, 7);
    pointLight.castShadow = true;
    // pointLight.position.set( 0, 20, -5 );
    pointLight.position.set(0, 0.65, -1.85);
    scene.add(pointLight);

    const sphereSize = 1;
    // const pointLightHelper = new THREE.PointLightHelper(pointLight, sphereSize);
    // scene.add(pointLightHelper);

    const spotLight = new THREE.SpotLight(0xffffff, 1.05, 25, 0.15, 1);
    spotLight.position.set(2, 3, 10);
    spotLight.target.position.x = 1;
    spotLight.target.position.y = 0.85;
    spotLight.target.position.z = -1;
    spotLight.target.updateMatrixWorld();
    spotLight.castShadow = true;
    scene.add(spotLight);

    // const pointLightHelper2 = new THREE.DirectionalLightHelper(spotLight, sphereSize);
    // scene.add(pointLightHelper2);

    const spotLight2 = new THREE.SpotLight(0xffffff, 1, 25, 0.15, 1);
    spotLight2.position.set(-3, 4, 12);
    spotLight2.target.position.x = -1.5;
    spotLight2.target.position.y = 0.5;
    spotLight2.target.position.z = -1;
    spotLight2.target.updateMatrixWorld();
    spotLight2.castShadow = true;
    scene.add(spotLight2);

    // const pointLightHelper3 = new THREE.DirectionalLightHelper(spotLight2, sphereSize);
    // scene.add(pointLightHelper3);

    // RENDERER
    renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth - GUTTER_SCROLLER, HEIGHT);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    container.appendChild(renderer.domElement);

    // STATS
    // stats = new Stats();
    // stats.dom.setAttribute("id", "fpsElm");
    // container.appendChild(stats.dom);
    // TERRAIN
    const SizeOfMesh = 80;
    const terrainGeometry = new THREE.PlaneGeometry(20, 20, SizeOfMesh, SizeOfMesh);
    const terrainMaterial = new THREE.MeshPhongMaterial({
        color: 0x3b0855,//0x2194ce,
        polygonOffset: true,
        polygonOffsetFactor: 1, // positive value pushes polygon further away
        polygonOffsetUnits: 1,
        vertexColors: THREE.VertexColors,
        shininess: 5,//110,
        specular: 0x64cdd3,// 0x050505,
        // wireframe: true,
    });
    const terrainMaterialWire = new THREE.MeshPhongMaterial({
        color: 0x64cdd3, //0xff00ff,
        wireframe: true
    });


    terrain = new THREE.Mesh(terrainGeometry, terrainMaterial);
    terrainWire = new THREE.Mesh(terrainGeometry, terrainMaterialWire);
    terrain.receiveShadow = true;
    terrainWire.receiveShadow = true;

    terrain.rotation.x = terrainWire.rotation.x = THREE.Math.degToRad(-90);
    terrain.position.y = terrainWire.position.y - 2;

    terrainWire.position.y = terrain.position.y;

    terainVerts = terrain.geometry.vertices;
    terainWireVerts = terainVerts;
    // console.log("Amount of Verts need to animated: " + terainVerts.length);
    makeJaggedTerrain();

    scene.add(terrain, terrainWire);


    // PARTICLES
    const maxParticleCount = particleAmount * 2;
    pMaterial = new THREE.PointsMaterial({
        color: 0xf26b38, //0xFF0000,
        size: 0.1,
        blending: THREE.AdditiveBlending,
        // transparent: true,
        // sizeAttenuation: true
    });

    particlePositions = new Float32Array(maxParticleCount * 3);

    const particleLength = 20;
    const particleContainerWidth = 20;
    const particleContainerHeight = 10;

    const minVelocity = -0.025;
    const maxVelocity = 0.025;

    for (let i = 0; i < particleAmount; i++) {
        const xPos = Math.random() * particleContainerWidth - particleContainerWidth / 2;
        const yPos = Math.random() * particleContainerHeight - particleContainerHeight / 2;
        const zPos = Math.random() * particleLength - particleLength / 2;

        particlePositions[i * 3] = xPos;
        particlePositions[i * 3 + 1] = yPos;
        particlePositions[i * 3 + 2] = zPos;

        particlesData.push({
            // maxVelocity: 1,
            // minVelocity: -1,
            startNoise: getRandomVal(minVelocity, maxVelocity),
            position: new THREE.Vector3(xPos, yPos, zPos),
            velocity: new THREE.Vector3(
                getRandomVal(minVelocity, maxVelocity),
                getRandomVal(minVelocity, maxVelocity),
                getRandomVal(minVelocity, maxVelocity),
            ),
            animate: function () {
                //Z == FORWARDS AND BACKWARDS
                // const perlineAmount = 0.00000001;
                if (this.position.z >= (particleLength / 2)) {
                    this.velocity.z = -this.velocity.z;
                    // this.startNoise -=  perlineAmount;
                }
                if (this.position.z <= -(particleLength / 2)) {
                    this.velocity.z = Math.abs(this.velocity.z);
                    // this.startNoise +=  perlineAmount;
                }
                //x == LEFT AND RIGHT
                if (this.position.x >= (particleContainerWidth / 2)) {
                    this.velocity.x = -this.velocity.x;
                    // this.startNoise -=  perlineAmount;
                }
                if (this.position.x <= -(particleContainerWidth / 2)) {
                    this.velocity.x = Math.abs(this.velocity.x);
                    // this.startNoise +=  perlineAmount;
                }
                // // Y == UP AND DOWN
                if (this.position.y >= (particleContainerHeight / 2)) {
                    this.velocity.y = -this.velocity.y;
                    // this.startNoise -=  perlineAmount;
                }
                if (this.position.y <= -(particleContainerHeight / 2)) {
                    this.velocity.y = Math.abs(this.velocity.y);
                    // this.startNoise +=  perlineAmount;
                }


                // let perlinX = noise.simplex2(this.velocity.x , this.startNoise) ;
                // let perlinY = noise.simplex2(this.velocity.y , this.startNoise) ;
                // let perlinZ = noise.simplex2(this.velocity.z , this.startNoise) ;

                // console.log(perlinX);


                // console.log(perlin);
                // if(this.velocity.x >= this.maxVelocity) { this.velocity.x = 0; }
                // if(this.velocity.y >= this.maxVelocity) { this.velocity.y = 0; }
                // if(this.velocity.z >= this.maxVelocity) { this.velocity.z = 0; }
                //
                // if(this.velocity.x <= this.minVelocity) { this.velocity.x = 0; }
                // if(this.velocity.y <= this.minVelocity) { this.velocity.y = 0; }
                // if(this.velocity.z <= this.minVelocity) { this.velocity.z = 0; }
                //
                //
                // this.velocity = new THREE.Vector3(0,0,0);
                // this.velocity.add(  new THREE.Vector3(perlinX,perlinY,perlinZ) );
                // console.log(this.velocity);
                // this.velocity = new THREE.Vector3(perlin,perlin,perlin);
                this.position.add(this.velocity);


            }
        });
    }

    particles.setDrawRange(0, particleAmount);
    particles.addAttribute('position', new THREE.BufferAttribute(particlePositions, 3).setDynamic(true));
    // create the particle system
    pointCloud = new THREE.Points(particles, pMaterial);
    const group = new THREE.Group();
    // group.position.z = -3;
    group.position.y = 2;
    group.add(pointCloud);
    scene.add(group);

    // const helperBox = new THREE.BoxHelper(group, 0xffffff);
    // scene.add(helperBox);


    // LOAD THE MODEL
    let loader = new THREE.GLTFLoader();
    loader.load('models/text-idodigital-v3.blend-with-cell-fracture.glb', function (gltf) {
        let jasper = gltf.scene;

        jasper.position.y += 0.8;
        jasper.position.z -= 1.5;
        // jasper.position.y -= 2;
        // jasper.position.z -= 10;
        gltf.scene.traverse(function (node) {
            const name = node.name.substring(0, 9);

            switch (name) {
                case "I001_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["i1"].push(node);
                    break;
                case "D001_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["d1"].push(node);
                    break;
                case "O001_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["o1"].push(node);
                    break;
                case "D002_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["d2"].push(node);
                    break;
                case "I002_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["i2"].push(node);
                    break;
                case "G001_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["g1"].push(node);
                    break;
                case "I003_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["i3"].push(node);
                    break;
                case "T001_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["t1"].push(node);
                    break;
                case "A001_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["a1"].push(node);
                    break;
                case "L001_cell":
                    node.tweenOut = returnTweenOutTLM(node);
                    node.tweenIn = returnTweenInTLM(node);
                    IDD["l1"].push(node);
                    break;
            }

            if (node instanceof THREE.Mesh) {
                node.castShadow = true;
            }
        });

        scene.add(jasper);

        // console.log(IDD);

        setUpIdoDigitalExplosionAnimation();


    }, undefined, function (e) {
        console.error(e);
    });

    window.addEventListener('resize', onWindowResize, false);
    document.getElementById(WRAPPER_DIV).addEventListener('mousemove', onMouseMove, false);

    // GLOBAL STAT STUFF_____________
    ANIMATE_TERRAIN = false;
    // fpsCanvasElm = document.getElementById("fpsElm");
    // fpsCanvasElm.style.display = "none";
    //_______________________________

}

function makeJaggedTerrain() {

    Noise.seed(Math.random());

    for (let i = 0; i < terainVerts.length; i++) {
        terainVerts[i].z = Noise.perlin2(terainVerts[i].x * 0.22, terainVerts[i].y * 0.22) * 1.75;
        terainVerts[i].z += getRandomVal(-0.065, 0.065);
        terainWireVerts[i].z = terainVerts[i].z;
    }

    terrain.geometry.__dirtyVertices = true;
    terrain.geometry.dynamic = true;
    terrain.geometry.elementsNeedUpdate = true;

    terrainWire.geometry.__dirtyVertices = true;
    terrainWire.geometry.dynamic = true;
    terrainWire.geometry.elementsNeedUpdate = true;
}

function reset() {

    ANIMATE_TERRAIN = false;
    camera.position.set(0, 0, 10);
    makeJaggedTerrain();
    document.getElementById("aniTrain").checked = false;

}

function onWindowResize() {

    camera.aspect = window.innerWidth / HEIGHT;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth - GUTTER_SCROLLER, HEIGHT);

    windowWidth = window.innerWidth - GUTTER_SCROLLER;

    if (window.innerWidth <= MIN_WIDTH) {
        scene.scale.x = 0.665;
        scene.scale.y = 0.665;
        scene.scale.z = 0.665;
    }
}

function onMouseMove(e) {


    windowCenterX = windowWidth / 2;

    isMouseRightOfScreen = ((windowWidth / 2) - e.clientX) <= 1;


    rotationVal = Math.abs((windowWidth / 2) - e.clientX).toFixed(6);
    normalizedRotationPos = normalizeVals(rotationVal, windowCenterX, 0) * (Math.PI / 30);

    if (lastChangedMousePos === undefined) {
        lastChangedMousePos = Math.abs(normalizedRotationPos);
    }

    normalizedRotationPos = isMouseRightOfScreen ? -normalizedRotationPos : normalizedRotationPos;


    if (!(lastChangedMousePos > Math.abs(normalizedRotationPos))) {

        if (
            isMouseRightOfScreen && camera.getWorldDirection(scene.position).x > 0.4 ||
            !isMouseRightOfScreen && camera.getWorldDirection(scene.position).x < -0.4
        ) {
            return;
        }

        camera.translateX(normalizedRotationPos);
    }

    lastChangedMousePos = Math.abs(normalizedRotationPos);

    // console.log(camera.getWorldDirection(scene.position).x);

}

function animate() {
    requestAnimationFrame(animate);
    render();
    // stats.update();
}

function render() {
    if (!READY_TO_RENDER) {
        return
    }
    // camera.lookAt();

    animateParticles();
    if (ANIMATE_TERRAIN) {
        animateTerrain();
    }

    controls.update();
    renderer.clear();
    renderer.render(scene, camera);

}

function getRandomVal(min, max) {
    return Math.random() * (max - min) + min;
}

function normalizeVals(val, max, min) {
    return (val - min) / (max - min);
}

function animateParticles() {

    for (let i = 0; i < particlesData.length; i++) {

        particlesData[i].animate();

        particlePositions[i * 3] = particlesData[i].position.x;
        particlePositions[i * 3 + 1] = particlesData[i].position.y;
        particlePositions[i * 3 + 2] = particlesData[i].position.z;
    }

    particles.addAttribute('position', new THREE.BufferAttribute(particlePositions, 3).setDynamic(true));
}

function animateTerrain() {
    for (let i = 0; i < terrain.geometry.vertices.length; i++) {
        terrain.geometry.vertices[i].z = Noise.perlin2(
            terrain.geometry.vertices[i].x * 0.22,
            (terrain.geometry.vertices[i].y * 0.22) + flying) * 2.2;

        // terrain.geometry.vertices[i].z  += noise.perlin2(getRandomVal(0.0004, 0.0005 + flying));


        terrainWire.geometry.vertices[i].z = terrain.geometry.vertices[i].z;

        flying += 0.0000005;

        terrain.geometry.elementsNeedUpdate = true;
        terrainWire.geometry.elementsNeedUpdate = true;
    }
}

function returnTweenOutTLM(_node) {
    return TweenLite.to(_node.position, 1.75, {
        ease: Expo.easeOut,
        x: getRandomVal(-15, 15),
        y: getRandomVal(-2, 5),
        z: getRandomVal(-10, 10),
    }).pause();
}

function returnTweenInTLM(_node) {
    return TweenLite.to(_node.position, 1.25, {
        ease: Expo.easeIn,
        x: _node.position.x,
        y: _node.position.y,
        z: _node.position.z,
    }).pause();
}

function setUpIdoDigitalExplosionAnimation() {

    const explodeAnimationQueue = new Sys.LoopingQueue();
    explodeAnimationQueue.add(explodeOut());
    explodeAnimationQueue.add(aniIn());

    explodeAnimationQueue.execute();


}

function explodeOut() {
    const task = new Sys.BaseTask();

    task.name = "explodeOut";

    task.execute = function () {

        const timeline = new TimelineMax();

        const delay = 0.075;
        timeline.to({nothing: 0}, 4, {nothing: 1});
        timeline.call(() => {
            IDD["i1"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["d1"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["o1"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["d2"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["i2"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["g1"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["i3"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["t1"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["a1"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, delay, {nothing: 1});
        timeline.call(() => {
            IDD["l1"].forEach((item) => {
                item.tweenOut.restart();
                item.tweenOut.play();
            });
        });
        timeline.to({nothing: 0}, 1, {nothing: 1});

        timeline.call(() => {
            task.notifyComplete();
        });

    };

    return task;
}


function aniIn() {
    const task = new Sys.BaseTask();
    task.execute = function () {

        task.name = "AniIn";

        const timeline = new TimelineMax();

        timeline.to({nothing: 0}, 4, {nothing: 1});
        timeline.call(() => {
            IDD["i1"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["d1"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["o1"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["d2"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["i2"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["g1"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["i3"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["t1"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["a1"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.call(() => {
            IDD["l1"].forEach((item) => {
                item.tweenIn.restart();
                item.tweenIn.play();
            });
        });
        timeline.to({nothing: 0}, 1.5, {nothing: 1});

        timeline.call(() => {
            task.notifyComplete();
        });

    };


    return task;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// document.getElementById("fps").addEventListener("click", () => {
//
//     if (fpsCanvasElm.style.display === "none") {
//         fpsCanvasElm.style.display = "block";
//         return;
//     }
//
//     fpsCanvasElm.style.display = "none";
// });

document.getElementById("panZoom").addEventListener("click", () => {

    if (controls.enabled === false) {
        controls.enabled = true;
        controls.enableZoom = true;
        document.getElementById("warnMsg").style.display = "block";
        return;
    }

    controls.enabled = false;
    controls.enableZoom = false;
    document.getElementById("warnMsg").style.display = "none";


});

document.getElementById("aniTrain").addEventListener("click", () => {

    ANIMATE_TERRAIN = !!!ANIMATE_TERRAIN;

});

document.getElementById("resetCanvas").addEventListener("click", () => {
    reset();
});


window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', 'UA-49597608-1');
