

require("./css/main.scss");

window.Stats = require("./js/stats.min.js");
window.noise = require("./js/PerlinNoise/perlin.js");
require("./js/libs/Tasks.js");
require("./js/main.js");


document.getElementById('year').innerText = new Date().getFullYear()-1988;
